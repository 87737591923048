@import url(https://fonts.googleapis.com/css?family=Poppins:600,700|Rubik:400,500);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
tr:first-child p,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Fonts */
/* Typography & Colors */
body {
  color: #454245;
  font-size: 16px;
  margin-top: 3rem;
  font-family: "Rubik", sans-serif; }

h1 {
  font-weight: 700;
  font-family: Poppins, "Lucida Grande", Tahoma, sans-serif;
  font-size: 2.75rem;
  margin: 0.67em 0; }


h2,
tr:first-child p {
  font-weight: 1.375rem;
  font-weight: 600;
  font-family: Poppins, "Lucida Grande", Tahoma, sans-serif;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
  font-size: 1.375rem; }

hr {
  margin-bottom: 0.9375rem; }

th {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  text-align: left; }

p {
  font-size: 1rem;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.625rem;
  margin-top: 0rem;
  margin-bottom: 0rem; }

ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem; }

li {
  padding: 1rem 0.3rem 0.3rem 0.3rem;
  margin-left: 10px;
  border-bottom: 1px solid #e8e8e8; }

table {
  border-radius: 0.375rem;
  border-collapse: collapse; }

tr {
  padding: 1.25rem 0;
  min-height: 1.25rem;
  border-radius: 0.375rem; }

tr:first-child p {
  font-size: 1rem; }

tr:nth-child(odd) {
  background-color: #f7f7f7; }

td:first-child {
  height: 2.875rem;
  width: 20.375rem; }

td {
  border-spacing: 0px;
  padding: 0.5rem 0.75rem;
  vertical-align: middle; }

td:nth-child(2) {
  padding: 0.5rem 0rem; }

tr:nth-child(odd) td:first-child {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  height: 2.875rem;
  width: 20.375rem;
  background-color: #f7f7f7;
  border-right: 0px; }

tr:nth-child(odd) td:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background-color: #f7f7f7;
  height: 2.875rem;
  width: 14.0625rem;
  border-left: 0rem; }

kbd {
  line-height: 0.95rem;
  border: 0.0625rem solid #ccc;
  display: inline-block;
  min-width: 1em;
  padding: 0.5em 0.6em 0.4em 0.6em;
  margin: 0.125rem;
  font-weight: normal;
  font-size: 0.85rem;
  font-family: "CircularPro", "appleLogo", sans-serif;
  text-align: center;
  text-decoration: none;
  border-radius: 0.3em; }

/* Main */
.wrapper {
  margin: 0.6rem auto;
  width: 90vw; }

.wrap {
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(auto-fill, minmax(21.875rem, 1fr));
  grid-template-rows: repeat(20, 4.0625rem);
  grid-auto-flow: dense;
  height: auto; }

.box-wrapper {
  border-radius: 0.375rem;
  border: 1px solid #e8e8e8;
  padding-top: 1rem;
  padding: 0.9375rem; }

/* grid specific styling*/
.box {
  order: 1; }

.v3 {
  grid-row: span 3; }

.v4 {
  grid-row: span 4; }

.v5 {
  grid-row: span 5; }

.v6 {
  grid-row: span 5; }

.v7 {
  grid-row: span 6; }

.v8 {
  grid-row: span 8; }

.v9 {
  grid-row: span 9; }

.v22,
.v23,
.v24,
.v25,
.v26 {
  grid-row: span 22; }

.vscode {
  order: 0; }

@media print {
  body {
    margin: 0;
    color: #000;
    background-color: #fff; }
  .links > * {
    display: none; } }

