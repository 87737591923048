@import "reset.scss";

/* Fonts */
@import url("https://fonts.googleapis.com/css?family=Poppins:600,700|Rubik:400,500");

$font-stack-rubik: "Rubik", sans-serif;
$font-stack-poppins: Poppins, "Lucida Grande", Tahoma, sans-serif;

/* Typography & Colors */

$table-border: #e5e5e5;
$table-row: #f7f7f7;
$body-grey: #454245;
$box-border: #e8e8e8;

body {
  // color: $primary-color;
  color: $body-grey;
  font-size: 16px;
  margin-top: 3rem;
  font-family: $font-stack-rubik;
}

h1 {
  font-weight: 700;
  font-family: $font-stack-poppins;
  font-size: 2.75rem;
  margin: 0.67em 0;
}

h2 {
  font-weight: 1.375rem;
  font-weight: 600;
  font-family: $font-stack-poppins;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
  font-size: 1.375rem;
}

hr {
  margin-bottom: 0.9375rem;
}
th {
  font-family: $font-stack-rubik;
  font-weight: 500;
  font-size: 1rem;
  text-align: left;
}

p {
  font-size: 1rem;
  font-family: $font-stack-rubik;
  font-weight: 400;
  line-height: 1.625rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

li {
  padding: 1rem 0.3rem 0.3rem 0.3rem;
  margin-left: 10px;
  border-bottom: 1px solid $box-border;
}

table {
  border-radius: 0.375rem;
  // border: 1px solid $table-border;
  border-collapse: collapse;
}
tr {
  padding: 1.25rem 0;
  // border: 3px solid green;
  min-height: 1.25rem;
  border-radius: 0.375rem;
}
tr:first-child p {
  @extend h2;
  font-size: 1rem;
}
tr:nth-child(odd) {
  background-color: $table-row;
}

td:first-child {
  height: 2.875rem;
  width: 20.375rem;
}
td {
  border-spacing: 0px;
  padding: 0.5rem 0.75rem;
  vertical-align: middle;
}

td:nth-child(2) {
  padding: 0.5rem 0rem;
  // border: 1px solid red;
}

tr:nth-child(odd) td:first-child {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  height: 2.875rem;
  width: 20.375rem;
  background-color: $table-row;
  border-right: 0px;
}

tr:nth-child(odd) td:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background-color: $table-row;
  height: 2.875rem;
  width: 14.0625rem;
  border-left: 0rem;
}
kbd {
  line-height: 0.95rem;
  border: 0.0625rem solid #ccc;
  display: inline-block;
  min-width: 1em;
  padding: 0.5em 0.6em 0.4em 0.6em;
  margin: 0.125rem;
  font-weight: normal;
  font-size: 0.85rem;
  font-family: "CircularPro", "appleLogo", sans-serif;
  text-align: center;
  text-decoration: none;
  border-radius: 0.3em;
}

/* Main */
.wrapper {
  margin: 0.6rem auto;
  width: 90vw;
}
.wrap {
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(auto-fill, minmax(21.875rem, 1fr));
  grid-template-rows: repeat(20, 4.0625rem);
  // grid-template-rows: repeat(auto-fill, minmax(50px, 1fr));
  grid-auto-flow: dense;
  // margin: 0 auto;
  height: auto;
}

.box-wrapper {
  border-radius: 0.375rem;
  border: 1px solid $box-border;
  padding-top: 1rem;
  // margin-top: -1px;
  padding: 0.9375rem;
}

/* grid specific styling*/
.box {
  order: 1;
  // border: 3px solid red;
}

.v3 {
  grid-row: span 3;
}
.v4 {
  grid-row: span 4;
}
.v5 {
  grid-row: span 5;
}
.v6 {
  grid-row: span 5;
}
.v7 {
  grid-row: span 6;
}

.v8 {
  grid-row: span 8;
}
.v9 {
  grid-row: span 9;
}
.v22,
.v23,
.v24,
.v25,
.v26 {
  grid-row: span 22;
}

.vscode {
  order: 0;
}

@media print {
  body {
    margin: 0;
    color: #000;
    background-color: #fff;
  }

  .links > * {
    display: none;
  }

  // .wrap {
  //   grid-template-columns: max-content;
  // }
}
